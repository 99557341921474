



































































































import { Component, Watch, Prop } from "vue-property-decorator";
import Finial from "@/components/designer/sidebar/frame/Finial.vue";
import RadioInput from "@/components/designer/reusable/RadioInput.vue";
import UIkit from "uikit";
import { mixins } from "vue-class-component";
import { FrameStore, LayoutStore } from "@/mixins/store";
import {
  BottomPole,
  Finial as FinialEnum
} from "@/models/configurator/configurator_options";
import { ProductLineName } from "@/models/products/collection";
import { FinishDisplayName, FinishHandle } from "@/models/products/finish";
import { StockMixin } from "@/mixins/stock";

@Component({
  components: {
    Finial,
    RadioInput
  }
})
export default class FinialGroup extends mixins(FrameStore, LayoutStore, StockMixin) {
  protected FinialEnum = FinialEnum;
  protected BottomPole = BottomPole;
  protected selectedFinial: FinialEnum | null = null;
  protected selectedPole: BottomPole | null = null;
  protected ProductLineName = ProductLineName;
  mounted() {
    this.readStoreData();
  }

  get showBottomPole(): boolean {
    let show = false;
    if (
      this.collection.handle === ProductLineName.MontereyPulleyLift ||
      this.collection.handle === ProductLineName.MontereyAutoTilt ||
      this.collection.handle === ProductLineName.MontereyCrank ||
      this.collection.handle === ProductLineName.Greenwich ||
      this.collection.handle === ProductLineName.Catalina ||
      this.collection.handle === ProductLineName.Laurel
    ) {
      show = true;
    }
    return show;
  }
  /**
   * Check the store for preset data. If there is none, set default
   * selected to the first one in finish array
   */
  protected async readStoreData(): Promise<void> {
    if (!this.bottomPole) {
      this.selectedPole = BottomPole.Standard;
      this.addBottomPole(BottomPole.Standard);
      await this.$viewer.ChangeBottomPole(this.selectedPole!);
    } else {
      this.selectedPole = this.bottomPole;
    }
    if (!this.finial) {
      this.selectedFinial = FinialEnum.Ball;
      this.addFinial(FinialEnum.Ball);
    } else {
      this.selectedFinial = this.finial;
    }
  }

  @Watch("selectedPole")
  protected async selectPole(): Promise<void> {
    if (this.selectedPole) {
      this.addBottomPole(this.selectedPole);
      await this.$viewer.ChangeBottomPole(this.selectedPole);
    }
  }

  protected async selectFinial(finial: FinialEnum): Promise<void> {
    this.selectedFinial = finial;
    const finialFinish =
      this.collection.handle !== ProductLineName.Greenwich &&
      this.collection.handle !== ProductLineName.GreenwichGSeries
        ? this.finish
        : { handle: FinishHandle.MS, display_name: FinishDisplayName.MS };
    this.addFinial(finial);
    this.addFinialFinish(finialFinish);
    await this.$viewer.ChangeFinial(finial, finialFinish.handle);
  }

  protected toggleModal(): void {
    UIkit.modal(document.getElementById("bottom-pole") as HTMLElement).show();
  }
}
