
































import { Component, Watch, Prop } from "vue-property-decorator";
import RadioInput from "@/components/designer/reusable/RadioInput.vue";
import UIkit from "uikit";
import { mixins } from "vue-class-component";
import { FrameStore, LayoutStore } from "@/mixins/store";
import {
  Spigot,
} from "@/models/configurator/configurator_options";
import { ProductLineName } from "@/models/products/collection";
import { StockMixin } from "@/mixins/stock";

@Component({
  components: {
    RadioInput
  }
})
export default class SpigotGroup extends mixins(FrameStore, LayoutStore, StockMixin) {
  protected Spigot = Spigot;
  protected selectedSpigot = Spigot.Standard;
  protected ProductLineName = ProductLineName;
  mounted() {
    this.readStoreData();
  }

  get showSpigot(): boolean {
    let show = false;
    if (
      this.collection.handle === ProductLineName.Eclipse ||
      this.collection.handle === ProductLineName.Aurora
    ) {
      show = true;
    }
    return show;
  }

  /**
   * Check the store for preset data. If there is none, set default
   * selected to the first one in finish array
   */
  protected async readStoreData(): Promise<void> {
    if (!this.spigot) {
      this.selectedSpigot = Spigot.Standard;
      this.addSpigot(Spigot.Standard);
    } else {
      this.selectedSpigot = this.spigot;
    }
  }

  @Watch("selectedSpigot")
  protected async selectSpigot(): Promise<void> {
    if (this.selectedSpigot) {
      this.addSpigot(this.selectedSpigot);
    }
  }
}
